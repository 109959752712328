











































































































import tareaModule from "@/store/modules/tarea-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxSortable } from "devextreme-vue/sortable";
import { Component, PropSync, Vue } from "vue-property-decorator";
import { tarea } from "@/shared/dtos/tareas/tarea";
import { tareaAllDto } from "@/shared/dtos/tareas/tareaAllDto";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
    DxScrollView,
    DxSortable,
    KanbanList: () => import("@/components/TypeView/Kanban_List_Gantt.vue"),
    TareaFormulario: () =>
      import("@/views/proyectos/tareas/tareasFormulario.vue"),
    XBtnActiveInActive: () =>
      import("@/components/TypeView/x-btnActiveInActive.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class TareasKanban extends Vue {
  @PropSync("id_proyecto", { default: -1 }) id_proyectosync!: number;
  @PropSync("tareas", { default: [] })
  tareas_list!: tareaAllDto[];
  public lists: any[] = [];
  public search: string = "";
  public shw_type: string = "active";

  public enumeracines_estados: enumeracion[] = [];

  public show_dialog_tareas: boolean = false;
  public id_tarea: number = -1;

  created() {
    this.GetTareas();
    enumeracionModule.getenumeraciones_fromtipo(6).then((x: enumeracion[]) => {
      this.enumeracines_estados = x;
    });
  }

  public get tareas() {
    this.lists = [];
    this.estados.forEach((status) => {
      if (this.search.trim() !== "") {
        this.lists.push({
          estado: status,
          tareas: tareaModule.tareas_proyecto.filter((task) => {
            return (
              task.id_estado === status.id &&
              task.nombre.toLowerCase().includes(this.search.toLowerCase())
            );
          }),
        });
      } else {
        this.lists.push({
          estado: status,
          tareas: tareaModule.tareas_proyecto.filter(
            (task) => task.id_estado === status.id
          ),
        });
      }
    });
    return "";
  }
  public get estados() {
    return this.enumeracines_estados;
  }

  public change_data_activos(activos: string) {
    this.shw_type = activos;
    if (this.id_proyectosync === -1) {
      if (activos === "active") {
        return tareaModule.gettareaes_completoActivos();
      } else {
        return tareaModule.gettareaes_completoInActivos();
      }
    } else {
      return tareaModule.gettarea_proyecto_act_inact({
        id: this.id_proyectosync,
        act: activos === "active",
      });
    }
  }

  get get_style_height() {
    let alto = this.$vuetify.breakpoint.height;
    return "height:" + (alto - 218) + "px";
  }

  public GetTareas() {
    if (this.id_proyectosync === -1) {
      return this.change_data_activos(this.shw_type);
    } else {
      return this.change_data_activos(this.shw_type);
    }
  }

  public close_dialog() {
    this.show_dialog_tareas = false;
    this.GetTareas().then((x: tareaAllDto[]) => {
      this.tareas_list = x;
    });
    this.id_tarea = -1;
  }

  onTaskDragStart(e: any) {
    e.itemData = e.fromData[e.fromIndex];
  }

  onTaskDrop(e: any) {
    e.itemData = e.fromData.tareas[e.fromIndex];
    e.itemData.id_estado = e.toData.estado.id;
    e.itemData.orden = e.toIndex;
    //llamo a la api
    tareaModule
      .modificartarea(new tarea(e.itemData))
      .then(() => this.GetTareas());
  }

  get_style() {
    let alto = this.$vuetify.breakpoint.height;
    return "max-height:" + (alto - 218) + "px";
  }

  getPriorityStyle(task: tareaAllDto) {
    return ` background:grey`; //${task.prioridad.color}`;
  }

  onListReorder(e: any) {
    const list = this.lists.splice(e.fromIndex, 1)[0];
    this.lists.splice(e.toIndex, 0, list);

    const status = this.estados.splice(e.fromIndex, 1)[0];
    this.estados.splice(e.toIndex, 0, status);
  }

  editar(obj: tareaAllDto) {
    this.id_tarea = obj.id;
    this.show_dialog_tareas = true;
  }
  nueva_tarea() {
    this.$router.push({ name: RouterNames.proyectoskanban });
  }
}
