import { BaseDto } from "@/shared/dtos/base-dto";

export class tarea extends BaseDto {
  public nombre!: string;
  public descripcion!: string;
  public fecha_inicio!: Date;
  public fecha_fin!: Date;
  public fecha_check!: Date;
  public progreso!: number;
  public subproyecto!: boolean;
  public id_usuario!: number;
  public id_empresa!: number;
  public id_estado!: number;
  public id_tipo!: number;
  public id_proyecto!: number;
  public id_inventario!: number;
  public id_departamento!: number;
  public id_cabezera_plantilla!: number;
}
