import { store } from "@/store/store";
import { tarea } from "@/shared/dtos/tareas/tarea";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { tareaAllDto } from "@/shared/dtos/tareas/tareaAllDto";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { inventarioTareasDto } from "@/shared/dtos/tareas/inventarioTareasDto";

@Module({
  namespaced: true,
  name: "tareaModule",
  store,
  dynamic: true,
})
class tareaModule extends VuexModule {
  public tareaes: tarea[] = [];
  public tarea: tarea = new tarea();
  public tareas_proyecto: tareaAllDto[] = [];
  public files: document_fileDto[] = [];
  public tareas_inventario: inventarioTareasDto[] = [];

  @Action({ commit: "onGettareaes" })
  public async gettareaes() {
    return await ssmHttpService.get(API.tarea);
  }

  @Action({ commit: "onGettarea" })
  public async gettarea(id: any) {
    return await ssmHttpService.get(API.tarea + "/" + id);
  }

  @Action({ commit: "onGetTareaProyecto" })
  public async gettarea_proyecto(id: any) {
    return await ssmHttpService.get(API.tarea + "/tareas_proyecto/" + id);
  }

  @Action({ commit: "onGetTareaProyecto" })
  public async gettarea_proyecto_act_inact({ id, act }: any) {
    return await ssmHttpService.get(
      API.tarea + "/tareas_proyecto/" + id + "/" + act
    );
  }

  @Action({ commit: "onGetTareaProyecto" })
  public async gettareaes_completo() {
    return await ssmHttpService.get(API.tarea + "/tareas_completo/");
  }

  @Action({ commit: "onGetTareaMovil" })
  public async gettareaes_movil() {
    return await ssmHttpService.get(API.tarea + "/tareas_movil/");
  }
  @Action({ commit: "onGetTareaMovil" })
  public async gettareaes_movil_id(id:number) {
    return await ssmHttpService.get(API.tarea + "/tareas_movil/"+id);
  }

  @Action({ commit: "onGetTareaProyecto" })
  public async gettareaes_completoActivos() {
    return await ssmHttpService.get(API.tarea + "/activos/");
  }

  @Action({ commit: "onGetTareaProyecto" })
  public async gettareaes_completoGant() {
    return await ssmHttpService.get(API.tarea + "/gantt/");
  }

  @Action({ commit: "onGetTareaProyecto" })
  public async gettareaes_completoInActivos() {
    return await ssmHttpService.get(API.tarea + "/inactivos/");
  }

  @Action({ commit: "onGetproyectofiles" })
  public async getfiles(id: any) {
    return await ssmHttpService.get(API.tarea + "/documents/" + id);
  }

  @Action
  public async guardartarea(tarea: tarea) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.tarea, tarea.toJson());
  }

  @Action
  public async modificartarea(tarea: tarea) {
    return ssmHttpService.put(API.tarea + "/" + tarea.id, tarea);
  }

  @Action
  public async eliminartarea(tarea: tarea) {
    return ssmHttpService.delete(API.tarea + "/" + tarea.id, null, false);
  }

  @Action({ commit: "onGetproyectofiles" })
  public async eliminar_documento({ object, doc }: any) {
    return await ssmHttpService.put(
      API.tarea + "/documents/" + object.id,
      doc,
      false
    );
  }

  @Mutation
  public onGetTareaMovil(res: inventarioTareasDto[]) {
    this.tareas_inventario = res;
  }

  @Mutation
  public onGetproyectofiles(res: document_fileDto[]) {
    this.files = res ? res.map((x) => new document_fileDto(x)) : [];
  }

  @Mutation
  public onGettareaes(res: tarea[]) {
    this.tareaes = res ? res.map((x) => new tarea(x)) : [];
  }

  @Mutation
  public onGettarea(res: tarea) {
    this.tarea = new tarea(res);
  }

  @Mutation
  public onGetTareaProyecto(res: tareaAllDto[]) {
    this.tareas_proyecto = res ? res.map((x) => new tareaAllDto(x)) : [];
  }
}
export default getModule(tareaModule);
