import { departamento } from "@/shared/dtos/departamento";
import { empresa } from "@/shared/dtos/empresa";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { Usuario } from "@/shared/dtos/usuario";
import { tarea } from "@/shared/dtos/tareas/tarea";
import { proyecto } from "../proyecto";

export class tareaAllDto extends tarea {
  public estado!: enumeracion;
  public tipo!: enumeracion;
  public usuario!: Usuario;
  public empresa!: empresa;
  public departamento!: departamento;
  public proyecto!: proyecto;
}
